import React from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import InitPaymentForm from './forms/InitPaymentForm';

class Home extends React.Component {

  render() {
    return (
      <div className="">
          <Header />
          <InitPaymentForm />
          <Footer />
      </div>
    );
  }
  
}

export default Home;