import React from 'react';
import axios from 'axios';
import env from "react-dotenv";
import { HalfMalf } from 'react-spinner-animated';
import { NumericFormat } from 'react-number-format';

class InitPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.initTransactionRef = React.createRef();
    this.state      =  {
      license: '',
      name:    '',
      email:   '',
      amount:  '',
      obs:     '',
      url:     '',
      token:   '',
      errors:  {},
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    //License
    if (!fields["license"]) {
      formIsValid = false;
      errors["license"] = "Debe ingresar la patente.";
    }

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Debe ingresar el nombre.";
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Debe ingresar un correo electrónico.";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "El correo electrónico no es válido.";
      }
    }

    //Name
    if (!fields["amount"]) {
      formIsValid = false;
      errors["amount"] = "Debe ingresar el monto.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: (name === 'license') ? value.toUpperCase() : value
    });
  }

  async submit(){
    let errors = {};
    
    if (this.handleValidation()) {
      this.setState({ 'isLoading': true });

      let formData = new FormData();
      formData.append("license", this.state.license);
      formData.append("name", this.state.name);
      formData.append("email", this.state.email);
      formData.append("amount", this.state.amount.replace(".", ""));
      formData.append("obs", this.state.obs);

      return axios.post(
        env.BACKEND_API_BASE_URL + 'api/tusmultas/init-payment',
        formData, 
        {
          headers: { 
            'Content-Type' : 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          }
      }).then(response => {

        this.setState({
          token : response.data.token,
          url   : response.data.url
        },() => {
            this.initTransactionRef.current.submit();
            this.setState({ 'isLoading': false });
        });
        
      }).catch(e => {
        errors["paymentProcess"] = "No es posible realizar pagos en este momento. Prueba nuevamente más tarde.";
        this.setState({ 'errors': errors });
        this.setState({ 'isLoading': false });
      })
    } 
  }

  render() {
    return (
      <main className="main-content  mt-0">
        { this.state.isLoading ? <HalfMalf text={"Iniciando pago"} bgColor={"#fff"} center={true} width={"150px"} height={"150px"}/> : null }
        
        <section className="mb-1">
          <div className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style={{ backgroundImage: `url(https://www.tusmultas.cl/wp-content/uploads/2018/10/wallpaper_nubes2.png)` }}>
            
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 text-center mx-auto">
                  <h1 className="text-white mb-2 mt-5">Portal de pagos</h1>
                  <h5 className="text-lead text-white">Completa el formulario y realiza tu pago a través de <img id="logo-webpay" src="assets/img/logo_webpay.png" alt="logo_webpay" width="180"/></h5>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-lg-n10 mt-md-n11 mt-n10">
              <div className="col-xl-6 col-lg-5 col-md-7 mx-auto">
                <div className="card z-index-0">
                  <div className="row px-xl-5 px-sm-4 px-3">

                  </div>
                  <div className="card-body">
                    <div className="invalid-feedback">
                      {this.state.errors["paymentProcess"]}
                    </div>
                    <div>
                      <div className="mb-3 input-icons">
                        <i className="fa fa-cc-jcb icon"></i>
                        <input type="text" name="license" value={this.state.license} onChange={this.handleChange} maxLength={6} className={`form-control ${this.state.errors["license"] ? 'is-invalid' : ''}`}  placeholder="Patente" aria-label="Patente" aria-describedby="email-addon"  />
                        <div className="invalid-feedback">
                          {this.state.errors["license"]}
                        </div>
                      </div>
                      <div className="mb-3 input-icons">
                        <i className="fa fa-user icon"></i>
                        <input type="text" name="name" value={this.state.name} onChange={this.handleChange} maxLength={50} className={`form-control ${this.state.errors["name"] ? 'is-invalid' : ''}`} placeholder="Nombre" aria-label="Nombre" aria-describedby="email-addon" />
                        <div className="invalid-feedback">
                          {this.state.errors["name"]}
                        </div>
                      </div>
                      <div className="mb-3 input-icons">
                        <i className="fa fa-envelope icon"></i>
                        <input type="email" name="email" value={this.state.email} onChange={this.handleChange} maxLength={100} className={`form-control ${this.state.errors["email"] ? 'is-invalid' : ''}`} placeholder="Correo electrónico" aria-label="Correo electrónico" aria-describedby="email-addon" />
                        <div className="invalid-feedback">
                          {this.state.errors["email"]}
                        </div>
                      </div>
                      <div className="mb-3 input-icons">
                        <i className="fa fa-usd  icon"></i>
                        <NumericFormat 
                          type="text"
                          name="amount"
                          thousandSeparator={'.'} 
                          decimalSeparator={','} 
                          onChange={this.handleChange}
                          decimalScale={0}
                          fixedDecimalScale 
                          className={`form-control ${this.state.errors["amount"] ? 'is-invalid' : ''}`} 
                          placeholder="Monto a pagar" 
                          aria-label="Monto a pagar"
                        />
                        <div className="invalid-feedback">
                          {this.state.errors["amount"]}
                        </div>
                      </div>
                      <div className="mb-3">
                        <textarea rows="3" name="obs" value={this.state.obs} onChange={this.handleChange} maxLength={500} className="form-control textarea" placeholder="Observaciones" aria-label="Observaciones" aria-describedby="email-addon" ></textarea>
                      </div>
                      
                      <div className="text-center">
                        <button type="button" onClick={() => this.submit()} className="btn w-100 my-4 mb-2 pay-button">PAGAR</button>
                      </div>

                      <div className="process-payment-error">
                        {this.state.errors["paymentProcess"]}
                      </div>

                      <p className="text-sm mt-3 mb-0 text-center"><img id="info-icon" alt="info" src="assets/img/info_icon.png" width="24"/>Tu pago está protegido. <img id="info-icon" alt="info" src="img/footer-payments.png"/></p>

                      <form name="transbank-wrapper" ref={this.initTransactionRef} method="post" action={this.state.url} style={{ display: `none` }}>
                        <input type="hidden" name="token_ws" value={this.state.token} />
                        <input type="submit" value="" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default InitPaymentForm;