import './App.css';
import React from 'react';
import Home from './components/Home';
import SuccessPayment from './components/SuccessPayment';
import FailedPayment  from './components/FailedPayment';
import AbortedPayment from './components/AbortedPayment';
import { BrowserRouter as Router, Routes, Route, useSearchParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import 'react-spinner-animated/dist/index.css'

/**
 * Webpay return url handler.
 * author:d3m0n10q3
 */
const RetrievePayment = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  console.log('retrieving payment');

  axios.get(window.env.BACKEND_API_BASE_URL + 'api/tusmultas/payment/get-tbk-reponse/' + searchParams.get("token_ws")).then(response => {
    console.log(response.data);
    if(response.data.status === 'Aprobado' ){        // ok
      //navigate("/success", { state: { name: response.data.name, email: response.data.email, amount: response.data.amount } });
      window.location.replace('https://www.tusmultas.cl/index.php/recibimos-tu-pago/');
    } else if(response.data.status === 'Rechazado' ) {   // notfound
      navigate("/failure", { state: { name: response.data.name } });
    } else if(response.data.status === 'Cancelado' ){   // otherwise
      navigate("/aborted", { state: { name: response.data.name } });
    }
  }).catch(e => {
    console.log(e);
    console.log('Critical ERROR');
  })
};


class App extends React.Component {
  render() {
    return (
       <Router>
            <Routes>
                 <Route exact path='/'                        element={< Home />}></Route>
                 <Route exact path='/success'                 element={< SuccessPayment />}></Route>
                 <Route exact path='/failure'                 element={< FailedPayment />}></Route>
                 <Route exact path='/aborted'                 element={< AbortedPayment />}></Route>
                 <Route exact path="/retrieve-payment"        element={< RetrievePayment />}></Route>
            </Routes>
       </Router>
   );
  }
}

export default App;