import React from 'react';

class Header extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent mt-4">
        <div className="container">
          <a id="logo_a" href="https://www.tusmultas.cl/">
            <img id="logo-webpage" src="https://www.tusmultas.cl/wp-content/uploads/2018/10/firma.png" alt="logo_tusmultas"/>
          </a>
        </div>
      </nav>
    );
  }
}

export default Header;