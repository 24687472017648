import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="text-center mt-5 mb-5">
        <p className="mb-0 text-secondary ">
          <b> tusmultas.cl</b>. Nos encargamos de tus multas y te representamos en los juzgados.
        </p>
      </footer>
    );
  }
}

export default Footer;