import React from 'react';
import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { useLocation} from 'react-router-dom';

function FailedPayment()  {
  const { state } = useLocation();

  return (
    <div>
      <Header />
      <main className="main-content  mt-0">
        <section className="mb-1">
          <div className="page-header align-items-start pt-5 pb-11 m-3 border-radius-lg" style={{ backgroundImage: `url(https://www.tusmultas.cl/wp-content/uploads/2018/10/backweb_4.jpg)` }} >
            <span className="mask opacity-6"></span>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 text-center mx-auto">
                  <h1 className="text-white mb-2 mt-7">Transacción fallida</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-lg-n10 mt-md-n11 mt-n10">
              <div className="col-xl-6 col-lg-5 col-md-7 mx-auto">
                <div className="card z-index-0">
                  <div className="row px-xl-5 px-sm-4 px-3">

                  </div>
                  <div className="card-body">
                  <img alt="fail-img" id="img-success" src="assets/img/failure2.png" width="150"/>
                  <div className="black col-lg-12 text-center mx-auto">
                    <h3 className="text-black mb-2 mt-2">Hubo un error al procesar tu pago</h3>
                  </div>
                  <div className="black col-lg-10 text-center mx-auto">
                    <p className="text-black mb-2 mt-2">{state ? state.name: ''}, tu pago no fue realizado. Intenta nuevamente.</p>
                  </div>
              <form >
                
                <div className="text-center">
                  <Link to="/" className="btn w-100 my-4 mb-2 return_to_home_fail">Volver al sitio principal</Link>
                </div>
              </form>
            </div>
          </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
    
  );
  
}

export default FailedPayment;